<template>
  <div class="opacity-page show">
    <div class="settings-content">
      <div class="list-crud row-space-tbf">
        <div class="space-left"></div>
        <div class="content settings">
          <div class="crud" id="list-items">
            <div class="box" v-if="viewCrud == 'create'">
              <div class="header">
                <div class="icon-header"><icon-upload /></div>
                <div class="name">{{ $t('nomenclature.import.title') }}</div>
              </div>
              <div class="form">
                <div class="form-group">
                  <div class="data-group import">
                    <label class="title">{{ $t('nomenclature.import.general_import') }}</label>
                    <label class="description">{{ $t('nomenclature.import.text_info') }}</label>
                  </div>
                  <div class="form">
                    <div class="input-group w-100">
                      <div class="add-task add-file">
                        <div class="header-input-file">
                          <div class="main-input file-group">
                            <button class="btn-tbf blue upload-btn" onclick="document.getElementById('otherFile').click();">
                              <span class="text">{{ $t('create-user.add-file') }}</span>
                            </button>
                            <div class="file-name">{{ fileUpload ? fileUpload.name : '' }}</div>
                            <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style="display:none" id="otherFile" type="file" v-on:change="onFileChange">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-submit">
                  <div class="error-msg file" v-show="error_message != ''">{{error_message}}</div>
                  <button id="buttonCreate" class="btn-tbf blue" @click="showModal('update', {type: 'upload_file', from: 'import_file'})">
                    <div class="loader"></div>
                    <span class="text">{{ $t('general.upload') }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="space-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import IconUpload from '../../Icons/Upload'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    IconUpload
  },
  data() {
    return {
      loaded: false,
      viewCrud: 'create',
      fileUpload: '',

      error_message: '',
    }
  },
  async mounted(){

    setTimeout(() => {
      var title = this.$t('nomenclature-sidebar.import');
      this.$root.$emit("navbar_title", title);
    }, 0)

    this.$root.$on('confirmmUpdate', () => {
      this.saveAction()
    })
  },
  beforeDestroy() {
    this.$root.$off('confirmmUpdate')
  },
  methods: {
    onFileChange(e) {
      this.fileError = ''

      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.fileUpload = files[0]
    },

    saveAction(){
      var buttonName = 'buttonCreate'
      var btnSubmit = document.getElementById(buttonName)
      var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
      var btnSubmitText = document.querySelector(`#${buttonName} .text`)
      btnSubmit.disabled = true
      btnSubmit.classList.add('loading')
      btnSubmitLoader.classList.add('onProgress')
      btnSubmitText.innerHTML = this.$t('btn-submit.loading')
      this.error_message = '';

      let formData = new FormData();

      formData.append("file", this.fileUpload);

      this.uploadFile(formData, btnSubmit, btnSubmitText, btnSubmitLoader)
    },

    showModal(type, data = false, itemId = false){
      if(this.fileUpload) {
        this.$root.$emit('open_modal', type, data, itemId);
      } else {
        var buttonName = 'buttonCreate'
        var btnSubmit = document.getElementById(buttonName)
        var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
        var btnSubmitText = document.querySelector(`#${buttonName} .text`)
        btnSubmit.disabled = true
        btnSubmit.classList.add('loading')
        btnSubmitLoader.classList.add('onProgress')
        btnSubmitText.innerHTML = this.$t('btn-submit.loading')

        this.error_message = ''

        btnSubmitLoader.classList.add('finish')
        setTimeout(()=>{
          btnSubmitText.innerHTML = this.$t('btn-submit.error_upload')
          btnSubmit.classList.add('error')
          btnSubmitLoader.classList.remove('onProgress', 'finish')
          btnSubmit.classList.remove('loading')
          setTimeout(()=>{
            btnSubmit.classList.remove('error')
            btnSubmitText.innerHTML = this.$t('general.upload')
          }, 1000)
        }, 100)
        btnSubmit.disabled = false
        this.error_message = this.$t('nomenclature.import.error')
      }
    },

    uploadFile(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
      axios.post(this.$auth.user().instance.id + `/import-general`, objData)
          .then(({data}) => {
            this.fileError = '';
            btnSubmitLoader.classList.add('finish')
            this.clearFileInput('#otherFile')
            setTimeout(()=>{
              btnSubmitText.innerHTML = this.$t('btn-submit.success')
              btnSubmit.classList.add('completed')
              btnSubmitLoader.classList.remove('onProgress', 'finish')
              btnSubmit.classList.remove('loading')
              setTimeout(()=>{
                btnSubmit.classList.remove('completed')
                btnSubmitText.innerHTML = this.$t('general.upload')
              }, 1000)
            }, 300)
          })
          .catch(error => {
            btnSubmitLoader.classList.add('finish')
            setTimeout(()=>{
              btnSubmitText.innerHTML = this.$t('btn-submit.error_upload')
              btnSubmit.classList.add('error')
              btnSubmitLoader.classList.remove('onProgress', 'finish')
              btnSubmit.classList.remove('loading')
              setTimeout(()=>{
                btnSubmit.classList.remove('error')
                btnSubmitText.innerHTML = this.$t('general.upload')
                if(error.response.status == 500){
                  this.error_message = this.$t('error.500')
                } else {
                  if(error.response.data.errors && error.response.data.errors.file[0]) {
                    this.error_message = error.response.data.errors.file[0]
                  } else {
                    this.error_message =  error.response.data.message
                  }
                }
              }, 1000)
            }, 300)
          })
          .finally(() => {
            setTimeout(()=>{
              btnSubmit.disabled = false
            }, 1500)
          })
    },
    clearFileInput(id) {
      console.log('here')
      $(id).val('');
      this.fileUpload = ''
    }
  }
};
</script>

<style lang="scss">
.error-msg{
  &.file{
    top: -25px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.content{
  &.settings{
    justify-content: center;
    .multiselect__single{
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-weight: normal;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    #list-items{
      flex: 0 0 70%;
      .input-group{
        &.settings {
          display: flex;
          .mr-4{
            margin-right: 4px;
          }
          .ml-4{
            margin-left: 4px;
          }
          .label-header {
            flex: 0 0 30%;
            margin-bottom: 0;
            min-width: 150px;
            position: relative;
            .error-msg{
              position: absolute;
              top: 0;
            }
            .label{
              font-weight: 400;
            }
          }
          .input-box{
            width: 100%;
          }
        }
      }
    }
  }
}
</style>